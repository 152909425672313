import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {GoogleOAuthProvider} from "@react-oauth/google";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
    //     <BrowserRouter>
    //         <App/>
    //     </BrowserRouter>
    // </React.StrictMode>
    <GoogleOAuthProvider clientId="916513908337-5h7ml16accak5m24q32606ejhhlopcl9.apps.googleusercontent.com">
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </GoogleOAuthProvider>
)


