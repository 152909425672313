import './App.css';
import {Route, Routes, useLocation} from 'react-router-dom';
import Dashboard from "./component/Dashboard";
import Homepage from "./component/Homepage";
import Login from "./component/Login";
import Footer from "./component/Footer";
import Header from "./component/Header";
import EventView from "./component/EventView";
import Register from "./component/Register"
import Error from "./component/Error";
import {useEffect, useState} from "react";
import ScrollToTopButton from "./component/ScrollToTopButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import apiRoot from "./api";
import useJwt from "./util/useJwt";

const App = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const [flex, setFlex] = useState(() => {
        return (currentPath === '/login' || currentPath === '/register')
    });
    const {getJwt, updateJwt, clearJwt} = useJwt();
    let jwt = getJwt();
    useEffect(() => {
        fetch(`${apiRoot}/api/auth/jwt`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            method: 'POST'
        }).then(response => {
            if (response.status !== 200) {
                clearJwt();
                jwt = '';
            }
        }).catch(error => {
            console.log(error);
        })
    }, []);
    useEffect(() => {
        setFlex((currentPath === '/login' || currentPath === '/register'))
    }, [location, jwt]);

    return (
        <>
            <div className="flex flex-col h-screen">
                <header className="sticky top-0 z-50">
                    <Header/>
                </header>
                <main className={"justify-center items-center h-screen m-5" + (flex ? "  flex " : " flex-1 ")}>
                    <Routes>
                        <Route path={'/'} element={<Homepage/>}/>
                        <Route path={'/dashboard'} element={
                            // <><PrivateRoute children={<Dashboard/>}/></>
                            <Dashboard/>
                        }/>
                        {/*<Route path={'/dashboard'} element={<Dashboard/>}/>*/}
                        <Route path={'/login'} element={<Login/>}/>
                        <Route path={'/register'} element={<Register/>}/>
                        <Route path={'/event/:id'} element={
                            // <><PrivateRoute children={<EventView/>}/></>
                            <EventView/>
                        }/>
                        {/*<Route path={'/event/:id'} element={<EventView/>}/>*/}
                        <Route path={'/error'} element={<Error/>}/>
                    </Routes>
                </main>
                <footer className="sticky bottom-0 z-50">
                    <Footer/>
                </footer>
                <ScrollToTopButton/>
            </div>
        </>

    );
}

export default App;